// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "V_jP";
export var marketplaceAppDevCaseSection = "V_jW";
export var marketplaceAppDevClientQuotes = "V_jY";
export var marketplaceAppDevIndustriesSection = "V_jN";
export var marketplaceAppDevOnlineSection = "V_jS";
export var marketplaceAppDevPracticesSection = "V_jT";
export var marketplaceAppDevPrimeSection = "V_jL";
export var marketplaceAppDevProcessSection = "V_jQ";
export var marketplaceAppDevProjLogoSection = "V_jV";
export var marketplaceAppDevServicesSection = "V_jM";
export var marketplaceAppDevTechSection = "V_jR";
export var marketplaceAppExpertiseSection = "V_jX";